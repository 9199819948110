// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
$pe-7s-font-path: "/fonts";
@import "node_modules/pixeden-stroke-7-icon/pe-icon-7-stroke/scss/pe-icon-7-stroke.scss";
@import "ct-navbar.css";

@import url(//fonts.googleapis.com/css?family=Open+Sans:300,400,600);

.gly-spin {
  -webkit-animation: spin 2s infinite linear;
  -moz-animation: spin 2s infinite linear;
  -o-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
}
@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(359deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
  }
}
@-o-keyframes spin {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.form-control.radius-input {
    background-color: #dbeef9 !important;
    border-radius: 3px;
    padding: 0 10px;
}

.alert-prepared {
    width: 420px;
    font-size: 13px;
    position: absolute;
    top: -100%;
    right: 50%;

    .title {
        font-size: 14px;
        font-weight: bold;
    }

}

.datepicker table tr td.disabled {
    color: #ccc !important;
}

button.action-print,
button.action-save-to-jpg,
button.action-save-to-pdf {
    outline: 0 none !important;
}

input.datepicker {
    font-weight: bold;
    font-size: 16px;
}

.margin-3 {
    margin: 3px;
}

.TOP-BUFFER-20 {
    margin-top: 20px;
}

.top-buffer-10 {
    margin-top: 10px;
}

.datepicker-days {
    display: block !important;
}

.black {
    color: black;
}

.navbar-form {
    #location-input {
         border:1px solid #ccc;
         height: 44px;
         padding-left:10px;
         width:300px;
         margin-top: 8px;
    }
}

.navbar-map {
    margin-bottom: 0;

    @media screen and (max-width: 1550px) {
        font-size: 10px;

        #location-input {
            width: 200px;
            font-size: 16px;
        }
    }

    @media screen and (max-width: 1300pX) {
        font-size: 9px;

        #location-input {
            width: 140px;
            font-size: 14px;
        }
    }
}

html, body {
    height: 100%;
}

input[type="file"] {
    color: black;
}

.switch-system {
    a {
        padding-top: 20px !important;
    }
}
.btn-address-logic {
    border-radius: 30px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
    padding: 8px 14px;
    font-size: 11px;
    min-width: 120px;
    color: white;
}

.btn-al-print {
    background: rgb(49, 179, 141);
}

.btn-al-login {
    background: white;
    color: rgb(40, 170, 204);
    border: 2px solid rgb(40, 170, 204);
}

.btn-al-register {
    background: white;
    color: rgb(32, 49, 56);
    border: 2px solid rgb(32, 49, 56);
}

.btn-login {
    background-color: rgb(40, 170, 204);
}

.btn-register {
    background-color: rgb(51,93,109);
}

.btn-black {
    background-color: black;
}

.btn-red {
    background-color: rgb(218, 33, 40);
    border-color: rgb(218, 33, 40);;
    color: white;
    &:hover {
        background-color: rgb(218, 33, 40);
        color: white;
        border-color: rgb(218, 33, 40);
    }
}

.btn-cancel {
    background-color: rgb(183,183,183);
    border-color: rgb(183,183,183);
    color: white;
    &:hover {
        background-color: rgb(183,183,183);
        border-color: rgb(183,183,183);
    }
}

.btn-white {
    background-color: rgb(247, 247, 247);
    border: none;
    color: rgb(218, 33, 40);
    &:hover {
        background-color: rgb(35, 31, 32);
    }

    &:focus, &:active {
        background-color: rgb(180, 29, 30);
        color: rgb(196, 99, 100);
        outline: none;
    }

    &.final {
        background-color: rgb(35, 31, 32);
        color: white;
    }
}

.display-wrapper {
    display: -webkit-flex;
    display: flex;
    height: 100%;

    .sidebar-user {
        width: 300px !important;
        min-width: 300px !important;
        max-width: 300px !important;
        #header-logo {
            width: 240px !important;
        }
    }
    #sidebar, #section2  {
        width: 33%;
        min-width: 465px;
        max-width: 550px;
        background: #28373c;
        background-size: 130% auto;
        color: #fff;
        position: relative;
        height: 100%;

        .alert-welcome {
            color: #064763;
            background-color: #D1E4EA;
            border: 1px solid #D1E4EA;
            font-size: 13px;

            .title {
                font-size: 14px;
                font-weight: bold;
                margin-bottom:7px;
            }

        }

        .al-section-dark, .al-section {
            background-color: rgba(63, 90, 101, 0.9);
            padding: 20px 15px 25px 15px;
            margin-bottom:20px;
            border-radius: 6px;
            width: 100%;
            display: block;

            h2 {
                margin: 0px 0px 10px 0px;
                padding: 0px;
                font-size: 14px;
                font-weight: bold;

                text-transform: uppercase;
            }
        }
        .al-section-dark {
            background-color: #2c4750;
        }

        .filter-results {
            .form-group {
                margin-bottom: 0px;
                .radio-button {
                    width: 32%;
                    font-weight:normal;

                }
            }
        }

        #addresses-found3, #addresses-found {
            background-color: #1b2527;
            color: #ccf4fe;
            border: 2px solid #ccf4fe;
            width: calc(100% - 113px);
            border-radius: 3px;
            font-weight:bold;
            font-size: 20px;
            padding-left: 10px;
            margin-left: 7px;
        }

        .location-search {
            position: relative;
			.btn, input {
                background: none;
                border: none;
            }

			input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                color: #DBEDF9;
    			font-style: italic;
			}
			input::-moz-placeholder { /* Firefox 19+ */
                color: #DBEDF9;
    			font-style: italic;
			}
			input:-ms-input-placeholder { /* IE 10+ */
                color: #DBEDF9;
    			font-style: italic;
			}
			input:-moz-placeholder { /* Firefox 18- */
                color: #DBEDF9;
    			font-style: italic;
			}


            input {
                box-shadow: none;
				padding-left: 0px;
				font-size: 22px;
				font-weight: normal;
                color: #DBEDF9;
            }

			input, .input-group-btn {
				border-radius: 0px;
                border-bottom: 1px solid #DCEEFA;

				i {
                    color: #28AACC;
                }
			}
        }

        .sidebar-content {
            padding: 10px 10px;
            height: 100%;
            overflow-y: auto;
            box-sizing: border-box;
        }

        #header-logo {
            width: 145px;
        }

        #footer-logo {
            padding-left: 20px;
            width: auto;
            margin-top: 7px;
        }

        .progress {
            height: 38px;
            .progress-bar {
                font-size: 16px;
                line-height: 36px;
            }
        }

        .section {
            margin-top: 28px;
            margin-bottom: 70px;

            h3 {
                font-size: 18px;
                letter-spacing: 5px;
            }

            h4 {
                font-size: 19px;
            }
        }

        .welcome {
            margin-top: 63px;
            p {
                font-size: 15px;
                font-weight: 300;
                line-height: 22px;
            }
            .buttons {
                .btn {
                    min-width: 110px;
                }
            }
        }

        .try-it {
            width:100%;
            margin-bottom: 0px;
            margin-top: 0px;

            .draw-controls {
                float: left;

                .control {
                    display: inline-block;
                    width: 30%;
                    text-align: center;
                    border-radius: 4px;
                    cursor: pointer;
                    border: 0;
                    color: #dbeef9;

                    p {
                        font-size: 12px;
                        margin-bottom: 2px;
                        font-weight: bold;
                    }

                    span {
                        width: 50px;
                        height: 50px;
                        margin: 5px;
                        margin-top: 0px;
                        display: inline-block;
                        border-radius: 5px;
                        border: 1px solid #324851;
                        background-color: #dbeef9;
                        background-size: 80%;
                        background-repeat: no-repeat;
                        background-position: center center;

                        img {
                            margin: 5px;
                        }
                    }

                    &.draw-polygon span {
                        padding: 2px;
                        background-image: url('img/add_polygon.svg');
                    }
                    &.draw-polygon.active span {
                        padding: 2px;
                        background-image: url('img/add_polygon_light.svg');
                    }
                    &.draw-circle span {
                        padding: 2px;
                        background-image: url('img/add_radius.svg');
                    }
                    &.draw-circle.active span {
                        padding: 2px;
                        background-image: url('img/add_radius_light.svg');
                    }
                    &.draw-line span {
                        padding: 2px;
                        background-image: url('img/add_line.svg');
                    }
                    &.draw-line.active span {
                        padding: 2px;
                        background-image: url('img/add_line_light.svg');
                    }

                    &:hover {
                        color: #dbeef9;
                        border: 0;
                    }
                    &.active {
                        color: #203135;
                        border: 0;

                        span {
                            background-color: #213037;
                            border: 1px solid #0d4d65;
                        }
                    }
                }
            }

            .radius-details, .radius-details2 {
                float:left;
                margin-top: 10px;
                width: 47%;
                input {
                    height: 27px;
                    width: 100px;
                    border: 1px solid #cccccc;
                    outline: none;
                    display: inline;
                    color: black;
                }
            }
        }

        .service-type {
            margin-top: 20px;
            background: rgb(205, 31, 37);
            padding: 1px 15px 10px 15px;
            h3 {
                font-size: 16px;

                .llm-tooltip {
                    margin-left: 10px;
                }
            }
            hr {
                border-color: #e3333a;
                margin-top: 15px;
                margin-bottom: 15px;
            }
        }

        .estimated-cost {
            background-color: #203138;
            padding-top: 12px;
            text-align: center;
            padding-bottom:12px;

            .estimated-cost-cost, .estimated-cost-vat {
                display:none;
            }
            .estimated-cost-total {
                color: #29abcb;
                font-size:20px;
            }
            .inc-vat {
                color: #29abcb;
                font-size: 12px;
                display: none;
            }

            h4 {
                color: #29abcb;
                text-align:center;
                display: block;
                font-size: 21px;
            }

            p {
                font-size: 12px;
                margin-bottom:0px;
            }
        }

        .accept-terms {
            margin-top: 15px;

            a {
                font-weight: bold;
            }
        }

        .order-save {
            margin-top: 13px;
        }


        .footer {
            font-size: 13px;
            color: #0e0e0e;
            position: absolute;
            bottom: 0;
            width: 100%;
            background: #1d2a30;
            height: 70px;

            .links {
                float: right;
                margin: 25px 26px;

                a {
                    color: white;
                    margin: 0 5px;
                }
            }
            .copy {
                float: left;
                & > * {
                    display: block;
                }
                .copyright {
                    color: #fff;
                    line-height: 0px;
                    padding-left: 20px;
                }
            }
        }
    }
    #signup {
        overflow: auto;
    }

    #signup, #signin {
        width: 33%;
        padding-top: 30px;
        min-width: 300px;
        max-width: 500px;

        .setup-account {
            font-weight: bold;
            color: black;
            padding-bottom: 40px;
            a {
                color: rgb(218, 33, 40);
            }
        }

        @media screen and (max-width: 992px) {
            padding-right: 35px;
            padding-left: 35px;
        }

        h1 {
            margin-bottom: 30px;
            color: black;
            font-weight: 100;
        }
        .btn {
            min-width: 200px;
        }
    }

    .map-container {
        flex: 1;
    	-webkit-flex: 1;
	height:100%;

        #user-content, #map {
            height: calc(100% - 78px);
        }

        #notification, #notification2 {
            border-radius:0px;
            position: absolute;
            z-index: 10;
            div {
                border-radius:0px;
            }
        }

        #notification2 {
            font-size: 17px;
            right:0px;
            left: 550px;

            .alert {
                width: 440px;
                margin: 0 auto;
                background: #DA2128;
                color: white;

                a {
                    color: white;
                    text-decoration: underline;
                }
                button {
                    background: white;
                    color: red;
                }
            }
        }

        #notification  {
            right:0px;
        }
    }
    #user-content {
        .wide-buttons {
            .btn {
                width: 100%;
            }
        }
    }

    /************* STEPS *************/
    .step-nav {
        margin-top: 30px;
        .step {
            cursor: pointer;
            min-height: 70px;
            border-radius: 2px;
            border: 1px solid black;
            padding-top: 4px;
            width: 100%;
            color: black;
            position: relative;

            .pencil {
                font-size: 22px;
                color: black;
                visibility: hidden;
            }

            h3 {
                margin: 0 0 0 20px;
                font-size: 32px;
                font-weight: bold;
                white-space: nowrap;
            }

            .info {
                font-size: 12px;
                letter-spacing: 2px;
                padding-top: 14px;
                height: 44px;
                line-height: 14px;
            }

            &:hover, &.active {
                color: white;
                border: 1px solid white;

                .pencil {
                    visibility: visible;
                }
            }
        }
    }

    #enter-addresses, #enter-addresses2 {
        width: 300px;

        #enter-address-count, #enter-address-count2 {
            color: rgb(181, 33, 38);
            border-top-left-radius: 3px;
            border: none;
            font-size: 33px;
            height: 45px;
            padding-left: 10px;
            font-weight: bold;
            border-bottom-left-radius: 3px;
        }

        #enter-addresses-addon, #enter-addresses-addon2 {
            background-color: rgb(181,33,38);
            color: white;
            font-size: 16px;
        }
    }

    .step-body {
        .buttons {
            .btn {
                min-width: 130px;
            }
        }

        .llm-tooltip {
            color: rgb(127, 23, 26);
            font-size: 20px;
            margin-top: -4px;
        }

        h3 {
            font-size: 13px;
            letter-spacing: 5px;
        }

        select {
            font-size: 16px;
            font-weight: bold;
            color: black;
            letter-spacing: 2px;
        }

        p.info {
            font-style: italic;
            margin-top: 16px;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 16px;
        }
    }

    .delete-artwork {
        cursor: pointer;
    }
    #artwork-files-list {
        p {
            margin-top: 0 !important;
        }
    }
    .step-body-2 {
        .llm-tooltip {
            margin-left: 10px;
        }

        .uploaded-files {
            min-height: 98px;
            overflow: auto;
        }

        h2 {
            padding: 0px;
            margin: 0px;
            margin-top:10px;
            height: 7px;
            font-size: 21px;
        }
    }
    .step-body-3 {
        .additional-notes {
            margin-bottom: 30px;
            min-height: 150px;
        }

        #samples-global-section {
            .sample-enabled-check {
                margin-top: 15px;
                margin-left: 5px;
            }

            INPUT {
                font-size:11px;
            }

        }
    }
}
.royal-mail {
    .uploaded-files {
        min-height: 0px !important;
    }
}

.user-details {
    margin: 50px;
    h1 {
        color: black;
        font-weight: 200;
        margin-bottom: 45px;
        margin-top: 0;
    }
    input {
        color: black;
    }
}

.llm-modal {
    .modal-header {
        border-bottom: none;

        h4 {
            margin: 33px 70px 20px 70px;
            font-size: 30px;
            line-height: 32px;
            font-weight: 200;
            color: rgb(218, 33, 40);
        }
    }
    .modal-body {
        margin: 0 70px;
        color: black;
        font-weight: 100;
        p {
            font-size: 23px;
        }
    }
}

.button-delete button {
    margin-top: 30px;
    margin-bottom: 30px;
}

.delete-cancel {
    font-size: 16px;
    text-decoration: underline;
    color: rgb(218, 33, 40);
    cursor: pointer;
}

#jobs-table {
    background: #fff;

    thead {
        th {
            background: #000;
            color: #fff;
            padding: 20px 10px;

            a {
                color:white;
            }
        }
    }
    td {
        padding-top: 15px;
    }
    .action {
        padding-top: 4px;
    }
}

.btn-loader {
    i {
        display: none;
    }
}
.loading {
    i {
        display: inline-block;
          -webkit-animation: spin 2s infinite linear;
          -moz-animation: spin 2s infinite linear;
          -o-animation: spin 2s infinite linear;
          animation: spin 2s infinite linear;
    }
}

/* admin view map */
.full-map {
    width:100%;
    height:100%;
    #map {
        width:100%;
        height:100%;
    }
}

#job-id-header {
    float: right;
    font-size: 29px;
}

#areaNameModal {
    color: #636b6f;
    .area-name {
        width: 100%;
    }
}



//Menu
.menu-btn {
    font-size: 39px;
    line-height: 1.9;
    cursor: pointer;
}

.address-logo {
    display: none;
    max-width: 250px;
    vertical-align: top;
    margin-top: 18px;
    margin-left: 20px;
}

.sidebar-btn {
    background-color: #27373b;
    border: none;
    border-radius: 0;
    min-height: 100%;
    margin: -1px 0 0 -16px;
    float: left;
    height: 75px;
    width: 60px;
    display: none;
    &:hover {
        background-color: #27373b !important;
    }
    span.icon-bar {
        width: 38px;
        &:nth-child(2),
        &:nth-child(3) {
            margin-bottom: 10px;
        }
    }
}

.small-al-section {
    position: fixed;
    left: 20px;
    bottom: 20px;
    width: 400px !important;
}

.al-section {
    .area-shading {
        float: left;
        display: block;
        width: 100%;
        padding-left: 6px;
    }
}


.display-wrapper #section2 {
    width: 0;
    display: none;
    min-width: 0;
    z-index: 2;
    .al-section-dark, .al-section {
        width: 100%;
        max-width: 550px;
    }
}


//Billing page
.checkout-page {
    .address {
        textarea {
            width: 100%;
            min-height:150px;
        }
    }
}

.billing-panel {
    max-width: 50%;
    margin: 50px auto 0;
}

//autocomplete
.autocomplete-results {
    display: none;
    cursor: pointer;
    background: #fff;
    min-height: 200px;
    max-height: 200px;
    position: absolute;
    left: 15px;
    top: 89px;
    width: 94%;
    overflow: auto;
    padding: 10px;
    box-sizing: border-box;
    span {
        color: #000;
        display: block;
        padding: 5px;
        &:hover {
            background: rgba(227, 227, 227, 0.4);
        }
    }
}

#addresses-found3 {
    color: #000;
    width: 100%;
}

.address-logo.display-logo {
    display: inline-block;
}

.results-loader {
    position: relative;
    .loading-overlay {
        position:relative;
        width:100%;
    }
}

.modal-header, .modal-body, #job-id {
    color: black;
}

.welcome {
    background-color: #292929 !important;
}

.welcome-wrapper {
    display: grid;
    align-items: center;
    justify-content: center;
    height: 100%;
    background: url('images/bg-map.png') no-repeat fixed 100% 100%;
    .panel {
        background-color: #3f5a65;
        border: 0;
        padding: 12px;
        border-radius: 6px;
        box-shadow: 0px 0px 20px 7px rgba(0, 0, 0, 0.7);
        .panel-heading {
            background-color: #1d2a30;
            border-bottom: 1px solid #3f5a65;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            a:hover {
                text-decoration: none;
            }
        }
        .panel-color {
            background-color: #1d2a30;
            border-radius: 6px;
            .panel-body {
                p {
                    color: #c4c4c4;
                    font-size: 14px;
                    background-color: #3f5a65;
                    padding: 12px;
                    border-radius: 6px;
                    span {
                        font-weight: 700;
                        color: #fff;
                        .color {
                            color: #29abcb;
                        }
                    }
                    &.purchase {
                        padding: 0;
                        text-align: center;
                        background-color: transparent;
                        margin-bottom: 0;
                        color: #fff;
                        font-size: 15px;
                        a {
                            color: #29abcb;
                        }
                    }
                }
            }
            label {
                color: #fff;
            }
            .custom-input {
                font-size: 15px;
                width: 100%;
                box-shadow: none;
                padding-left: 0;
                text-indent: 4px;
                font-weight: 700;
                color: #DBEDF9;
                border: 0;
                border-radius: 0;
                background: none;
                border-bottom: 1px solid #DCEEFA;
            }
            .btn-login {
                outline: none;
            }
            .btn-link {
                color: #29abcb;
            }
            .copyright {
                display: block;
                color: #fff;
                text-decoration: none;
                line-height: 5px;
                font-size: 12px;
                padding-bottom: 14px;
            }
            .border-top {
                border-top: 1px solid #3f5a65;
            }
        }
    }
}
